import { useVaporTheme } from "@onefront/react-sdk";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/Typography";

export const VaporThemeSwitcher = () => {
  const { theme, setTheme } = useVaporTheme();

  return (
    <Stack direction={"row"} spacing={2}>
      <Typography
        onClick={() => setTheme("fic")}
        style={{ textDecoration: theme === "fic" ? "underline" : "none" }}
      >
        Fic
      </Typography>
      <Typography
        onClick={() => setTheme("vapor-light")}
        style={{
          textDecoration: theme === "vapor-light" ? "underline" : "none"
        }}
      >
        TS Light
      </Typography>
      <Typography
        onClick={() => setTheme("vapor-dark")}
        style={{
          textDecoration: theme === "vapor-dark" ? "underline" : "none"
        }}
      >
        TS Dark
      </Typography>
    </Stack>
  );
};
