import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(/* webpackChunkName: "TradeItemsView" */ "./FeatureView")
);

export const tradeItemsView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "trade-items",
      element: <FeatureView name="TradeItemsView" />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "trade-items/:tradeItemId/quick-view",
      element: <FeatureView name="TradeItemsQuickView" />
    }
  }
];
