import { useEffect } from "react";
import DevStuff from "./DevStuff";
import { Navigate } from "react-router-dom";
import NavContent from "./NavContent";

// Fix Toolbar z-index
const HotfixToolbar = () => {
  useEffect(() => {
    setTimeout(() => {
      try {
        document.querySelector(
          "#root > div > div > div > header:nth-child(2)"
        ).style["z-index"] = 1;
      } catch {}
    }, 1500);
  }, []);
  return null;
};

export const dashboard = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/",
      element: <Navigate to={`trade-items`} />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/dev",
      element: <DevStuff />
    }
  },
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent
    }
  },
  // Fix Toolbar z-index
  {
    target: "$ONE_LAYOUT_ROUTES_AFTER",
    handler: {
      component: HotfixToolbar
    }
  }
];
